/* ChangePassword.css */

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    height: 100%;
  }
  
  .change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #5f2cff, #00b9f2);
  }
  
  .change-password-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .brand-name {
    color: #5f2cff;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .change-password-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #5f2cff;
  }
  
  .change-password-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;
  }
  
  .change-password-input:focus {
    outline: none;
    border-color: #5f2cff;
  }
  
  .change-password-button {
    background-color: #5f2cff;
    color: white;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
  }
  
  .change-password-button:hover {
    background-color: #4e24d4;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
  }
  